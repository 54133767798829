import React from 'react';
import game from '../assets/images/illust/game.png';
import logo from '../assets/images/brand/thumbnail_logo.png';
import chickenstock from '../assets/images/illust/foods/chickenstock.png';
import daegutang from '../assets/images/illust/foods/daegutang.png';
import galbi from '../assets/images/illust/foods/galbi.png';
import japchae from '../assets/images/illust/foods/japchae.png';
import meatggochi from '../assets/images/illust/foods/meatggochi.png';
import nacho from '../assets/images/illust/foods/nacho.png';
import namul from '../assets/images/illust/foods/namul.png';
import pizza from '../assets/images/illust/foods/pizza.png';
import potato from '../assets/images/illust/foods/potato.png';
import ramyeon from '../assets/images/illust/foods/ramyeon.png';
import songpyeon from '../assets/images/illust/foods/songpyeon.png';
import soup from '../assets/images/illust/foods/soup.png';
import ssalnoodle from '../assets/images/illust/foods/ssalnoodle.png';
import steak from '../assets/images/illust/foods/steak.png';
import tiramisu from '../assets/images/illust/foods/tiramisu.png';
import coffee from '../assets/images/illust/foods/coffee.png';
import chickenstockThumbnail from '../assets/images/illust/foods/thumbnail/chickenstock_thumbnail.png';
import daegutangThumbnail from '../assets/images/illust/foods/thumbnail/daegutang_thumbnail.png';
import galbiThumbnail from '../assets/images/illust/foods/thumbnail/galbi_thumbnail.png';
import japchaeThumbnail from '../assets/images/illust/foods/thumbnail/japchae_thumbnail.png';
import meatggochiThumbnail from '../assets/images/illust/foods/thumbnail/meatggochi_thumbnail.png';
import nachoThumbnail from '../assets/images/illust/foods/thumbnail/nacho_thumbnail.png';
import namulThumbnail from '../assets/images/illust/foods/thumbnail/namul_thumbnail.png';
import pizzaThumbnail from '../assets/images/illust/foods/thumbnail/pizza_thumbnail.png';
import potatoThumbnail from '../assets/images/illust/foods/thumbnail/potato_thumbnail.png';
import ramyeonThumbnail from '../assets/images/illust/foods/thumbnail/ramyeon_thumbnail.png';
import songpyeonThumbnail from '../assets/images/illust/foods/thumbnail/songpyeon_thumbnail.png';
import soupThumbnail from '../assets/images/illust/foods/thumbnail/soup_thumbnail.png';
import ssalnoodleThumbnail from '../assets/images/illust/foods/thumbnail/ssalnoodle_thumbnail.png';
import steakThumbnail from '../assets/images/illust/foods/thumbnail/steak_thumbnail.png';
import tiramisuThumbnail from '../assets/images/illust/foods/thumbnail/tiramisu_thumbnail.png';
import coffeeThumbnail from '../assets/images/illust/foods/thumbnail/coffee_thumbnail.png';
import titleThumbnail from '../assets/images/illust/foods/thumbnail/title_thumbnail.png';
import NotFoundPage from './404';

export default function Img() {
  return (
    <>
      <NotFoundPage />
      <img alt="chickenstock" src={chickenstock} style={{ display: 'none' }} />
      <img alt="daegutang" src={daegutang} style={{ display: 'none' }} />
      <img alt="galbi" src={galbi} style={{ display: 'none' }} />
      <img alt="japchae" src={japchae} style={{ display: 'none' }} />
      <img alt="meatggochi" src={meatggochi} style={{ display: 'none' }} />
      <img alt="nacho" src={nacho} style={{ display: 'none' }} />
      <img alt="namul" src={namul} style={{ display: 'none' }} />
      <img alt="pizza" src={pizza} style={{ display: 'none' }} />
      <img alt="potato" src={potato} style={{ display: 'none' }} />
      <img alt="ramyeon" src={ramyeon} style={{ display: 'none' }} />
      <img alt="songpyeon" src={songpyeon} style={{ display: 'none' }} />
      <img alt="soup" src={soup} style={{ display: 'none' }} />
      <img alt="ssalnoodle" src={ssalnoodle} style={{ display: 'none' }} />
      <img alt="steak" src={steak} style={{ display: 'none' }} />
      <img alt="tiramisu" src={tiramisu} style={{ display: 'none' }} />
      <img alt="coffee" src={coffee} style={{ display: 'none' }} />
      <img alt="chickenstockThumbnail" src={chickenstockThumbnail} style={{ display: 'none' }} />
      <img alt="daegutangThumbnail" src={daegutangThumbnail} style={{ display: 'none' }} />
      <img alt="galbiThumbnail" src={galbiThumbnail} style={{ display: 'none' }} />
      <img alt="japchaeThumbnail" src={japchaeThumbnail} style={{ display: 'none' }} />
      <img alt="meatggochiThumbnail" src={meatggochiThumbnail} style={{ display: 'none' }} />
      <img alt="nachoThumbnail" src={nachoThumbnail} style={{ display: 'none' }} />
      <img alt="namulThumbnail" src={namulThumbnail} style={{ display: 'none' }} />
      <img alt="pizzaThumbnail" src={pizzaThumbnail} style={{ display: 'none' }} />
      <img alt="potatoThumbnail" src={potatoThumbnail} style={{ display: 'none' }} />
      <img alt="ramyeonThumbnail" src={ramyeonThumbnail} style={{ display: 'none' }} />
      <img alt="songpyeonThumbnail" src={songpyeonThumbnail} style={{ display: 'none' }} />
      <img alt="soupThumbnail" src={soupThumbnail} style={{ display: 'none' }} />
      <img alt="ssalnoodleThumbnail" src={ssalnoodleThumbnail} style={{ display: 'none' }} />
      <img alt="steakThumbnail" src={steakThumbnail} style={{ display: 'none' }} />
      <img alt="tiramisuThumbnail" src={tiramisuThumbnail} style={{ display: 'none' }} />
      <img alt="coffeeThumbnail" src={coffeeThumbnail} style={{ display: 'none' }} />
      <img alt="titleThumbnail" src={titleThumbnail} style={{ display: 'none' }} />
      <img alt="gameImg" src={game} style={{ display: 'none' }} />
      <img alt="logo" src={logo} style={{ display: 'none' }} />
    </>
  );
}
